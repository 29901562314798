table {
	width: 100%;
}

.react-tabs__tab-list {
	border-bottom: 1px solid rgb(157, 162, 163);
	margin: 0 0 20px;
	padding: 0;
}

.react-tabs__tab {
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
	color: rgb(32, 119, 213);
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -1px;
	position: relative;
	list-style: none;
}

.react-tabs__tab.react-tabs__tab--selected {
	background: #fff;
	border-color: rgb(157, 162, 163);
	color: #222;
	border-radius: 5px 5px 0 0;
}
