.guides-table {
	border-spacing: 0;
}

.guides-table thead th {
	text-align: left;
}

.guides-table tbody tr {
	/* height: 80px; */
	cursor: pointer;
}

.guides-table tbody tr:hover td {
	background-color: #fafafa;
}

.guides-table tbody tr.pager:hover td {
	background-color: initial;
	cursor: auto;
}

.guides-table tbody td {
	border-bottom: 1px solid #eaeaea;
	padding: 5px 10px;
	font-size: 0.9rem;
}

.avatar-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
}

.avatar-image-big {
	height: 180px;
	width: 180px;
}

.guide-screen__title-table {
	margin-bottom: 2em;
}

.guide-invoices-container {
	max-height: 500px;
	overflow: auto;
	margin: 0 auto 40px auto;
}

.guide-new-invoice-button {
	margin-top: 100px;
	margin-left: auto;
}
