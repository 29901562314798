.title-container {
	margin-bottom: 20px;
}

.title-image {
	height: 200px;
	object-fit: cover;
}

.invoices-list {
	margin-left: 20px;
}