.prices-container {
	border: #bababa solid 1px;
	border-radius: 10px;
	margin: 10px;
	padding: 10px;
	margin-left: 0;
	margin-right: 0;
}

.prices-table-separator {
	margin: 10px 0;
	background-color: #bababa;
	color: #bababa;
	border: none;
	height: 1px;
}
