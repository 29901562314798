.trip-search-results {
	position: absolute;
	background: white;
	z-index: 1;
	border: solid 1px #666;
	padding: 5px;
	margin-top: -20px;
}

.trip-search-single-results {
	padding: 3px;
}

.trip-search-single-results:hover {
	background: #eaeaea;
	cursor: pointer;
}