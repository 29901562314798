.input-date {
	border-radius: 4px;
	height: 36px;
	padding: 8px;
	border: solid 1px rgb(214,214,214);
	font: inherit;
	margin-bottom: 5px;
}

.input-date:focus {
	outline-width: 0;
}