.prev-arrow {
	transform: rotate(180deg);
}

.icon-button {
	display: inline-flex;
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.label {
	padding: 0 7px;
}
