/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
	line-height: 20px;
}

.full-height {
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	overflow-y: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

table tbody tr td {
	vertical-align: middle;
}

table thead tr th {
	font-weight: 700;
}

h1 {
	font-size: 2.5rem;
	line-height: 2.5rem;
	margin-bottom: 0.625rem;
}
h2 {
	font-size: 2rem;
	line-height: 2rem;
	margin-bottom: 0.5rem;
}
h3 {
	font-size: 1.75rem;
	line-height: 1.75rem;
	margin-bottom: 0.4375rem;
}
h4 {
	font-size: 1.5rem;
	line-height: 1.5rem;
	margin-bottom: 0.375rem;
}
h5 {
	font-size: 1.25rem;
	line-height: 1.25rem;
	margin-bottom: 0.3125rem;
}
h6 {
	font-size: 1rem;
	line-height: 1rem;
	margin-bottom: 0.25rem;
}
