.invoices-table {
	border-spacing: 0;
}

.invoices-table thead th {
	text-align: left;
	padding: 10px 5px;
}

/* .invoices-table tbody tr {
	height: 80px;
	cursor: pointer;
} */

.invoices-table tbody td {
	border-bottom: 1px solid #eaeaea;
	font-size: 0.9rem;
	padding: 5px 10px;
	white-space: nowrap;
}

.invoices-table tbody tr:hover td {
	background-color: #fafafa;
}

.invoices-table tbody tr.pager:hover td {
	background-color: initial;
	cursor: auto;
}

.invoices-table tbody td {
	border-bottom: 1px solid #eaeaea;
	padding: 5px 10px;
	font-size: 0.9rem;
}

.link {
	font-size: inherit;
}

.new-invoice-button:not(:first-child) {
	margin-left: 5px !important;
}

.grid-button {
	align-self: center;
	padding-top: 16px !important;
}

.invoice-screen__title-table {
	margin-bottom: 2em;
}

.invoice-screen__info-section {
	display: flex;
	height: 100%;
}

.invoice-screen__detail-section {
	flex: 1;
	height: 100%;
	padding: 0 5px;
}

.invoice-screen__preview-section {
	flex: 2;
	border-left: 1px solid #dadada;
	height: 100%;
	padding: 0 10px;
}

.invoice-screen__preview-section iframe {
	width: 100%;
	height: 100%;
	min-height: 800px;
}

.invoice-checkbox {
	display: inline-flex;
	margin-left: 10px;
}

.invoice-type-select {
	padding: 8px;
}
