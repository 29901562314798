.grid-container {
	display: grid;
	grid-template-columns: 2fr 9fr;
	grid-template-rows: 1fr 20fr;
	gap: 1px 1px;
	grid-template-areas: 'Header Header' 'Sidebar Main';
	width: 100vw;
	height: 100vh;
}

.Header {
	grid-area: Header;
}
.Sidebar {
	grid-area: Sidebar;
	border-right: 1px solid #eaeaea;
}
.Main {
	grid-area: Main;
	padding: 1em;
}

.menu-item {
	display: block;
	height: 4em;
	width: 100%;
	background-color: white;
	color: #525252;
	padding: 0.5em;
	line-height: 3em;
	border-bottom: 1px solid #eaeaea;
	padding-left: 56px;
	cursor: pointer;
	text-decoration: none;
}

.menu-item:hover,
.menu-item.selected {
	background-color: #fafafa;
	font-weight: 700;
}

.welcome-h1 {
	font-size: 5em;
	text-align: center;
	margin-top: 20%;
	margin-bottom: 10px;
}

.muted {
	display: block;
	color: #999;
}

.text-center {
	text-align: center;
}

.main-title {
	margin-bottom: 0.5em;
}

.no-pad-left {
	padding-left: 0 !important;
}

.text-right {
	text-align: right !important;
}
