.date-input {
	border: unset;
	border-bottom: 1px dotted #000;
	display: inline;
	font: inherit;
	color: inherit;
	margin-right: 4px;
	background-color: unset;
}

.date-input:focus {
	outline-width: 0;
}