.ready-to-bill-table {
	border-spacing: 0;
}

.ready-to-bill-table thead th {
	text-align: left;
}

.ready-to-bill-table tbody td {
	border-bottom: 1px solid #eaeaea;
	padding: 5px 10px;
	font-size: 0.9rem;
}

.avatar-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
}

.avatar-image-big {
	height: 180px;
	width: 180px;
}

.booking-screen__title-table {
	margin-bottom: 2em;
}
