#root {
	height: 100vh;
}

.auth-container {
	width: 600px;
	margin: 10% auto;
}

.auth-title {
	margin-bottom: 1em;
}
