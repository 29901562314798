.bookings-table {
	border-spacing: 0;
}

.bookings-table thead th {
	text-align: left;
}

.bookings-table tbody tr {
	/* height: 80px; */
	cursor: pointer;
}

.bookings-table tbody tr:hover td {
	background-color: #fafafa;
}

.bookings-table tbody tr.pager:hover td {
	background-color: initial;
	cursor: auto;
}

.bookings-table tbody td {
	border-bottom: 1px solid #eaeaea;
	padding: 5px 10px;
	font-size: 0.9rem;
}

.avatar-image {
	height: 30px !important;
	width: 30px !important;
	border-radius: 50% !important;
}

.avatar-image-big {
	height: 180px !important;
	width: 180px !important;
}

.booking-screen__title-table {
	margin-bottom: 2em;
}
